import React from 'react'
import { Link, graphql } from 'gatsby'

import Environment from '../lib/Environment'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import CopyContainer from '../components/CopyContainer'

import './index.scss'


class Paper extends React.Component {

  render () {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const content = data.markdownRemark

    return (
      <Layout root={true} location={data.location} title={siteTitle}>
        <SEO
          title="Paper"
          keywords={[`paper`, `research`, `academic`]}
        />
      <CopyContainer>
        <h1>
          {content.frontmatter.title}
        </h1>
        <div className="CopyContainer-body" dangerouslySetInnerHTML={{ __html: content.html }} />
      </CopyContainer>
      </Layout>
    )
  }
}

export default Paper

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: "/paper" } }) {
      id
      html
      frontmatter {
        title
      }
    }
  }
`
